import axios from 'axios';
const SLIDER = "SLIDER"
const GET_NEWS = "GET_NEWS"
const GET_CATEGORIES = "GET_CATEGORIES"
const GET_POPULAR = "GET_POPULAR"
const GET_RANDOM = "GET_RANDOM"
const GET_NEWS_SINGLE = 'GET_NEWS_SINGLE'
const GET_YT_VIDEO = 'GET_YT_VIDEO'
const GET_NEWS_CAT = 'GET_NEWS_CAT'
const GET_RECOMANDATIONS = 'GET_RECOMANDATIONS'
const GET_PROGRAMS = 'GET_PROGRAMS'
const GET_LINKS = 'GET_LINKS'
const GET_ADS = 'GET_ADS'
const GET_ABOUTUS = 'GET_ABOUTUS'
const GET_GALLERY = 'GET_GALLERY'
const GET_INFONEWS = 'GET_INFONEWS'

const domain = 'https://oshtv.kg/api/v1/'    
// const domain = 'http://127.0.0.1:8000/api/v1/'    
export const dom = 'https://oshtv.kg/'
const initState = {
    slider: {},
    news:{},
    categories:{},
    popular:{},
    random:{},
    yt_video:{},
    news_detail:{},
    recomendations:{},
    news_cat:{},
    programs:{},
    links:{},
    ads:{},
    about_us:{},
    gallery:{},
    infonews:{},
};

export const app = (state = initState, action) => {
    const createCase = (key)=>{
        return {
            ...state,
            [key]:action[key]
        }
    }
    switch(action.type){
        case SLIDER:{
            return createCase('slider')
        }
        case GET_NEWS:{
            return createCase('news')
        }
        case GET_CATEGORIES:{
            return createCase('categories')
        }
        case GET_NEWS_SINGLE:{
            return createCase('news_detail')
        }
        case GET_POPULAR:{
            return createCase('popular')
        }
        case GET_RANDOM:{
            return createCase('random')
        }
        case GET_YT_VIDEO:{
            return createCase('yt_video')
        }
        case GET_RECOMANDATIONS:{
            return createCase('recomendations')
        }
        case GET_NEWS_CAT:{
            return createCase('news_cat')
        }
        case GET_PROGRAMS:{
            return createCase('programs')
        }
        case GET_LINKS:{
            return createCase('links')
        } 
        case GET_ADS:{
            return createCase('ads')
        }
        case GET_ABOUTUS:{
            return createCase('about_us')
        }
        case GET_GALLERY:{
            return createCase('gallery')
        }
        case GET_INFONEWS:{
            return createCase('infonews')
        }
        default:
            return state

    }
}
const getApi = (type, key, url) =>{
    return (dispatch) =>{
        axios(`${domain}${url}`)
        .then(({data}) => dispatch({type, [key]:data}))
    }
}
export const getSlider = () =>{
    return getApi(SLIDER, 'slider', 'news/slider/');
}
export const getInfoNews = () =>{
    return getApi(GET_INFONEWS, 'infonews', 'news/info_news/');
}
export const getGallery = () =>{
    return getApi(GET_GALLERY, 'gallery', 'gallery/');
}
export const getNews = (id, title, offset) =>{
    if(title){
        return getApi(GET_NEWS, 'news', `news/?search=${title}&limit=10&offset=${offset}`);
    }
    else if(id){
        return getApi(GET_NEWS, 'news', `news/?categories=${id}&limit=10&offset=${offset}`);
    }
    else if(offset){
        return getApi(GET_NEWS, 'news', `news/?limit=10&offset=${offset}`);
    }
    else{
        return getApi(GET_NEWS, 'news', `news/`);
    }
}
export const getPopular = (id, limit, offset)=>{
    if(id){
        return getApi(GET_POPULAR, 'popular', `news/popular/?categories=${id}&limit=10&offset=${offset}` )
    }
    else if(limit && offset){
        return getApi(GET_POPULAR, 'popular', `news/popular/?limit=10&offset=${offset}`)

    }
    else{
        return getApi(GET_POPULAR, 'popular', `news/popular/`)
    }
}
export const getRandom = ()=>{
    return getApi(GET_RANDOM, 'random', 'news/random/')
}
export const getNewsDetail = (id)=>{
    return getApi(GET_NEWS_SINGLE, 'news_detail', `news/${id}/`)
}
export const getNewsCat = (id)=>{
    return getApi(GET_NEWS_CAT, 'news_cat', `news/?categories=${id}`)
}
export const getCategories = ()=>{
    return getApi(GET_CATEGORIES, 'categories', 'category/')
}
export const getYtVideo = ()=>{
    return getApi(GET_YT_VIDEO, 'yt_video', 'news/yt_video/')
}
export const getRecomandations = ()=>{
    return getApi(GET_RECOMANDATIONS, 'recomendations', 'news/recomendations/')
}
export const getPrograms = ()=>{
    return getApi(GET_PROGRAMS, 'programs', 'programs/days/')
}
export const getLinks = ()=>{
    return getApi(GET_LINKS, 'links', 'settings/')
}
export const getADS = ()=>{
    return getApi(GET_ADS, 'ads', 'settings/ads/')
}
export const getAboutUS = ()=>{
    return getApi(GET_ABOUTUS, 'about_us', 'settings/about_us/')
}