import React,{useState, useEffect} from 'react'
import NewsCard from './components/NewsCard'
import { useSelector, useDispatch } from "react-redux";
import { getNews, getRandom } from '../../../../redux/reducers/app'
import './news.scss'
import { Link } from "react-router-dom";

export default function News() {
    const news = useSelector(s => s.app.news);
    const random = useSelector(s => s.app.random);
    const dispatch = useDispatch();
    const [allNews, setAllNews] = useState([])
    const [randomNews, setRandomNews] = useState([])
    const [newsCount, setNewsCount] = useState(0)
    
    useEffect(()=>{
        dispatch(getNews());
        dispatch(getRandom());
    }, [])
    useEffect(()=>{
        if(news.results && random.results){
            setAllNews(news.results.slice(0,5))
            setRandomNews(random.results)
            setNewsCount(news.count)
        }
    }, [news.results, random.results])
  return (
    <div className='news'>
        <div className="container">
            <div className="news__inner">
                <h1 className='title'>Жаңылыктар</h1>
                <div className="cards"> 
                {
                    allNews.map(e=>(
                        <NewsCard news={e}/>
                    ))
                }
                    <div className="recomandation">
                        <div className="text">
                            <span>{newsCount}</span>
                            <p>Жаңылыктар сизди күтүп жатат</p>
                            <Link to={'/News'} className='hover__button__type2 d-flex g-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none"><path d="M1 2.49292C1 1.69996 1.87878 1.22245 2.54409 1.65389L9.70615 6.2983C10.314 6.6925 10.314 7.58216 9.70615 7.97636L2.54409 12.6208C1.87878 13.0522 1 12.5747 1 11.7817V2.49292Z" fill="#EDEBE9" stroke="#EDEBE9" strokeLinecap="round" strokeLinejoin="round"/></svg>Баарын көрүү
                                </Link>
                        </div>
                        <div className="cards">
                            {
                                randomNews.map((e,i)=>(
                                    <a href={`/OneNews/${e.id}`} key={i} className="recomandation__card">
                                        <img src={e.image} alt="" />
                                        <Link to={`/OneNews/${e.id}`}>{e.title.slice(0,15)}</Link>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
