import React, {useState, useEffect} from 'react'
import usa from '../../../../assets/images/usa.png'
import china from '../../../../assets/images/china.png'
import euro from '../../../../assets/images/euro.png'
import kzt from '../../../../assets/images/kzt.png'
import './currency.scss'

function CurrencyDisplay({currency, img}){
    const [buyCurrency, setBuyCurrency] = useState(0)
    // useEffect(() => {
    
    //     const apiUrl = `https://v6.exchangerate-api.com/v6/de5ed0041e2be109b79de87b/latest/${currency}`;
    
    //     fetch(apiUrl)
    //       .then(response => response.json())
    //       .then(data => {
    //         setBuyCurrency(data.conversion_rates.KGS)
    //         return 0;
    //       })
    //       .catch(error => {
    //         console.error('Ошибка при получении данных о курсах валют:', error);
    //       });
    //   }, [currency]);
      return (
        <div className='currency_one'>
          {buyCurrency ? (
                <div className='card__curr'>
                    <div className='title'>
                        <img src={img   } alt={`Флаг ${currency}`} />
                        <p>{currency}</p>
                    </div>
                    <div className='info'>
                        <p><span>Покупка</span>{String(buyCurrency).slice(0,5)}</p>
                        <p><span>Продажа</span>{String(buyCurrency + 0.01).slice(0,5)}</p>
                    </div>
                </div>
            ) : (
            <p>Загрузка...</p>
            )}
        </div>
      );
}

export default function Currency() {
  return (
    <div className='currency'>
      <div className="currency__inner">
        <span>Курс валют</span>
        <hr />
        <div className="currencier">
            <CurrencyDisplay currency='USD' img={usa}/> 
            <CurrencyDisplay currency='EUR' img={euro}/> 
            <CurrencyDisplay currency='KZT' img={kzt}/> 
            <CurrencyDisplay currency='CNY' img={china}/> 
        </div>
      </div>
    </div>
  )
}
