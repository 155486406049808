import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import './about.scss'
import Header from '../Header/Header'
import HeaderADS from '../Header/components/HeaderADS/HeaderADS'
import { getAboutUS } from '../../redux/reducers/app';

export default function About() {
    const about_us = useSelector(s => s.app.about_us);
    const dispatch = useDispatch();
    const [slide, setSlide] = useState({})
    useEffect(()=>{
        dispatch(getAboutUS());
      }, [])
      useEffect(()=>{
        if(about_us.results)setSlide(about_us.results[0])
      }, [about_us])
    return (
        <>
            <HeaderADS />
            <Header top={'18vh'} />
            <div className='About'>
                <div className="main">
                    <h1>Биз жөнүндө</h1>
                    <br />
                        {slide?(
                        <p dangerouslySetInnerHTML={{ __html: slide.text }}></p>):"Загрузка..."}
                    <br />
                </div>
            </div>
        </>
    )
}
