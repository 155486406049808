import React, { useState, useEffect } from 'react'
import './tv_program.scss'
import Card_programm from './components/Card_programm/Card_programm'
import Header from '../Header/Header'
import { useSelector, useDispatch } from "react-redux";
import { getPrograms } from '../../redux/reducers/app';
import HeaderADS from '../Header/components/HeaderADS/HeaderADS';
export default function TV_program() {
    const programs = useSelector(s => s.app.programs);
    const dispatch = useDispatch();
    const [allprograms, setprograms] = useState([])
    const [program, setprogram] = useState({})
    useEffect(() => {
        dispatch(getPrograms());
    }, [])
    useEffect(() => {
        if (programs.results) {
            setprograms(programs.results)
            setprogram(programs.results[0])
        }
    }, [programs.results])
    return (
        <>
            <HeaderADS />

            <Header top={'18vh'} />
            <div className='TV_program'>
                <h1>Телепрограмма</h1>
                <div className="main">
                    {
                        allprograms !== [] ? (
                            allprograms.map(e => (
                                <div className="block">
                                    <div className="column colunm_1">
                                        {allprograms[0] === e ? (<button>Башка даталар</button>) : ''}
                                    </div>
                                    <div className="column colunm_2">
                                        <button>{e.week_day},{e.day}</button>
                                    </div>
                                    <div className="column colunm_3">
                                        {
                                            e ? (
                                                e.programs.map(j => (
                                                    <Card_programm time={j.time} theme={j.name} />
                                                ))) : 'faef'
                                        }

                                    </div>
                                </div>
                            ))) : "Загрузка..."
                    }


                </div>
            </div>
            <div className='TV_program media'>
                <h1>Телепрограмма</h1>
                <div className="main">
                    <div className="block">
                        <div className="column colunm_2">
                            {
                                allprograms !== [] ? (
                                    allprograms.map(e => (
                                        <button onClick={() => setprogram(e)} className={e === program ? 'active' : ''}>{e.week_day},{e.day}</button>
                                    ))) : "Загрузка..."
                            }
                        </div>

                        <div className="column colunm_3">
                            {
                                program.programs ? (
                                    program.programs.map(e => (
                                        <Card_programm time={e.time} theme={e.name} />
                                    ))) : 'Загрузка...'
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
