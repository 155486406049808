import React, { useState,useEffect } from 'react'
import './weather.scss'
import temp_up from '../../../../assets/icons/temp_up.png'
import temp_down from '../../../../assets/icons/temp_down.png'

function WeatherDisplay({ city }) {
    const [temperature, setTemperature] = useState('');
    const [weatherDescription, setWeatherDescription] = useState('');
    const [maxTemperature, setMaxTemperature] = useState('');
    const [minTemperature, setMinTemperature] = useState('');
    const [weatherIcon, setWeatherIcon] = useState('');
    const [name, setName] = useState('');
  
    useEffect(() => {
      const apiKey = '69a2583e95a5596cc67fd659a4ef7785';
  
      // Формируем URL запроса к API
      const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric&lang=ru`;
  
      // Отправляем GET запрос к API
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // Обновляем состояния данными о погоде
          setTemperature(data.main.temp);
          setWeatherDescription(data.weather[0].description);
          setMaxTemperature(data.main.temp_max);
          setMinTemperature(data.main.temp_min);
          setWeatherIcon(data.weather[0].icon);
          setName(data.name);
        })
        .catch(error => {
          console.error('Ошибка при получении данных о погоде:', error);
        });
    }, [city]);
  
    const getWeatherIconUrl = iconCode => {
      return `http://openweathermap.org/img/wn/${iconCode}.png`;
    };
  
    return (
      <div className='weather__inner'>
        {temperature !== null ? (
            <div className='weather_one'>
                <div>
                    <img src={getWeatherIconUrl(weatherIcon)} alt="Погодная иконка" />
                    <p className='h3'><span>{name}</span> {temperature>0 ?'+' + String(temperature).slice(0,2):'-' +String(temperature).slice(0,2)}°  </p>
                </div>
                <p>{weatherDescription}</p>
                <div>
                    <p><img src={temp_up} alt="" />{maxTemperature >0 ? '+' + String(maxTemperature).slice(0,2) : '-' + String(maxTemperature).slice(0,2)}°</p>
                    <p><img src={temp_down} alt="" />{minTemperature >0 ? '+' + String(minTemperature).slice(0,2) : '-' + String(minTemperature).slice(0,2)}°</p>
                </div>
          </div>
        ) : (
          <p>Загрузка...</p>
        )}
      </div>
    );
  }

export default function Weather() {
    return (
        <div className='weather'>
            <WeatherDisplay city={'Bishkek'}/>
            <WeatherDisplay city={'Osh'}/>
        </div>
    );
}
