import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getGallery } from '../../redux/reducers/app';
import HeaderADS from '../Header/components/HeaderADS/HeaderADS'
import Header from '../Header/Header'
import './gallery.scss'
export default function Gallery() {
  const gallery = useSelector(s => s.app.gallery);
  const dispatch = useDispatch();
  const [allGallery, setAllGallery] = useState([])
  useEffect(()=>{
    dispatch(getGallery())
  },[])
  useEffect(()=>{
    if (gallery.results) {
      setAllGallery(gallery.results)
    }
  }, [gallery.results])
  return (
    <>
      <HeaderADS />

      <Header top={'18vh'} />
      <div className="gallery">
        <div className="container">
          <div className="gallery__inner">
            
            <h1 className='title'>Фото Баян</h1>
            <div className="images">
              {
                allGallery?(
                  allGallery.map(e=>(
                    <img src={e.image} key={e.id} alt="" />
                  ))
                ):'Загрузка...'
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
