import React, { useEffect, useState } from 'react'
import './mainslider.scss'
import Weather from '../Weather/Weather'
import Currency from '../Currency/Currency'
import { Skeleton} from '@mui/material';
import down from '../../../../assets/icons/Down.png'
import downr from '../../../../assets/icons/Down-r.png'
import { useSelector, useDispatch } from "react-redux";
import { getSlider } from '../../../../redux/reducers/app'
import { Link } from "react-router-dom";

export default function MainSlider() {
  const slider = useSelector(s => s.app.slider);
  const dispatch = useDispatch();
  const [slide, setSlide] = useState({})
  const [num, setNum] = useState(0) 
  
  useEffect(()=>{
    dispatch(getSlider());
  }, [])
  useEffect(()=>{
    if(slider.results)setSlide(slider.results[num])
  }, [slider])
  const nextSlide = ()=>{
    if(slider.results){
    if(num< slider.results.length-1){setNum(num+1); setSlide(slider.results[num])}
    else{setNum(slider.results.length-1)}
    }
  }
  const prevSlide = ()=>{
    if(slider.results){
    if(num-1>0){setNum(num-1); setSlide(slider.results[num])}
    else{setNum(0); setSlide(slider.results[0])}
    }
  }
  useEffect(()=>{
    if(slider.results){
    if(num< slider.results.length){setSlide(slider.results[num])}
    }
  }, [num])
  const backgroundStyle = {
    backgroundImage: `url(${slide.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    paddingBottom:'50px',
  };

  return (
    <div className='slider' style={backgroundStyle}>
      <div className="wrapper">
      </div>
      <div className="container">
        <div className="slider__inner">
            <div className="news__info">
              <span className='main'>Негизги бет</span>
              <div className="news__inner">
                <div className="categories">
                  {
                    slide.categories ? (
                    slide.categories.map((e)=>(
                      <Link to={'/News'} className='categories__link category__button' key={e.id}>{e.name}</Link>
                    )))
                    : (<Skeleton variant="rectangular" width={'120vh'} height={'70vh'} />)
                  }
                </div>
                {
                  slide.title ?  (
                    <>
                      <h1>{slide.title}</h1>
                      <p dangerouslySetInnerHTML={{ __html: slide.description.slice(0,50) }}></p>
                      <div className='sliders'>
                          <img src={down} onClick={()=>{prevSlide()}} alt="←" /> <p>{slider.results?Number(slider.results.indexOf(slide))+1:'Загрузка'}/{slider.results?slider.results.length:'Загрузка'}</p> <img onClick={()=>{nextSlide()}} src={downr} alt="→" />
                      </div>

                      <a href={`/OneNews/${slide.id}`} className='hover__button'>Кененирээк</a>
                    </>
                  ): (<Skeleton variant="rectangular" width={'20px'} height={'18px'} />)
                }
                
              </div>
            </div>
            <div className="news__dop_info">
              <Weather/>
              <Currency/>
            </div>
        </div>
      </div>
      
    </div>
  )
}
