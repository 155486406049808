import React from 'react'

export default function Card_programm({time,theme}) {
  return (
    <div className='Card_programm'>
        <h2>{time}</h2>
        <p>{theme}</p>
    </div>
  )
}
