import React, { useState, useEffect } from 'react';
import date from '../../assets/icons/date.png'
import logo from '../../assets/icons/logo.png'
import yt_arr from '../../assets/icons/yt_arr.png'
import search from '../../assets/icons/search.png'
import './Header.scss'
import Currency from '../Home/components/Currency/Currency';
import Weather from '../Home/components/Weather/Weather';
import { Link } from 'react-router-dom';
function formatDate(date) {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  return new Date(date).toLocaleDateString('ru-RU', options);
}

const currentDate = new Date();
console.log(formatDate(currentDate));

export default function Header({ top }) {
  const [NavPanel, setNavPanel] = useState(false)
  const [PogodaPanel, setPogodaPanel] = useState(false)
  const [VolutaPanel, setVolutaPanel] = useState(false)

  const [paddingTop, setPaddingTop] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop <= 30) {
        setPaddingTop(30 - scrollTop);
        setIsFixed(false);
      } else {
        setPaddingTop(0);
        setIsFixed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
  }, []);

  const containerStyle = {
    paddingTop: `${paddingTop}px`,
    position: isFixed ? 'fixed' : 'absolute',
    top: isFixed ? '30px' : top,
    transition: 'all 0.5s ease',
  };

  const currentDate = new Date();
  return (
    <>
      <div className='header' style={containerStyle}>
        <div className="wrapper">
          <div className="container">
            <div className="header__inner">
              <div className="header__links">
                <Link to={'/'} className='link'><img src={logo} alt="" /></Link>
                <Link to={"/"} className='link'>Башкы бет</Link>
                <Link to={"/News"} className='link'>Жаңылыктар</Link>
                <Link to={"/Tv-program"} className='link'>Программалар</Link>
                <Link to={"/Gallery"} className='link'>Фото баян</Link>
                <Link to={"/About"} className='link'>Биз жөнүндө</Link>
                <Link to={"/InfoGraf"} className='link link-has_arrow'>Инфографика</Link>
                <a href={"#contacts"} className='link link-has_arrow'>Байланыш</a>
                <Link to={"/Publication"} className='link link-has_arrow'>Жарнама</Link>
                
              </div>
              <div className="header__buttons">
              <Link to={'/search'} className='search__btn' >
                  <img className='search__button' src={search} alt=""/>
                </Link>
                <a className="date__btn">
                  <img src={date} alt="" />
                  {formatDate(currentDate)}
                </a>
                <a href='https://www.youtube.com/@oshtvtrk/videos' className="yt__btn">
                  <img src={yt_arr} alt="" />
                  Youtube
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`header header_media ${NavPanel ? 'active' : ''}`} style={containerStyle}>
        <div className="wrapper">
          <div className="container">
            <div className="header__inner">
              <div className="header__links">
                <Link to={"/"} className='link'><img src={logo} alt="" /></Link>

              </div>
              <div className="header__buttons">
                <Link to={'/search'} className='search__btn' >
                  <img className='search__button' src={search} alt=""/>
                </Link>
                <button className={`btn_1 ${NavPanel ? 'closed' : ''}`} onClick={e => setPogodaPanel(!PogodaPanel)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="23" viewBox="0 0 29 23" fill="none">
                    <path d="M21.6588 9.04015H20.1564C19.7103 7.25788 18.7885 5.64042 17.4957 4.37156C16.203 3.1027 14.5911 2.23333 12.8433 1.86222C11.0955 1.49111 9.2818 1.63315 7.60833 2.27219C5.93487 2.91124 4.46872 4.02168 3.37649 5.47731C2.28426 6.93295 1.60975 8.67543 1.4296 10.5067C1.24945 12.3381 1.57088 14.1848 2.35737 15.8371C3.14386 17.4894 4.36388 18.881 5.87879 19.8538C7.39369 20.8266 9.14275 21.3415 10.9272 21.3402H21.6588C23.2401 21.3402 24.7565 20.6922 25.8746 19.5389C26.9927 18.3855 27.6209 16.8212 27.6209 15.1902C27.6209 13.5591 26.9927 11.9948 25.8746 10.8414C24.7565 9.6881 23.2401 9.04015 21.6588 9.04015Z" stroke="white" strokeWidth="1.61538" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <button className={`btn_2 ${NavPanel ? 'closed' : ''}`} onClick={e => setVolutaPanel(!VolutaPanel)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                    <path d="M5.91992 1V19.04" stroke="white" strokeWidth="1.61538" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.02 4.2793H3.87C3.10883 4.2793 2.37883 4.58167 1.8406 5.1199C1.30237 5.65813 1 6.38813 1 7.1493C1 7.91047 1.30237 8.64046 1.8406 9.17869C2.37883 9.71692 3.10883 10.0193 3.87 10.0193H7.97C8.73117 10.0193 9.46117 10.3217 9.9994 10.8599C10.5376 11.3981 10.84 12.1281 10.84 12.8893C10.84 13.6505 10.5376 14.3805 9.9994 14.9187C9.46117 15.4569 8.73117 15.7593 7.97 15.7593H1" stroke="white" strokeWidth="1.61538" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <button className="btn_3" onClick={e => setNavPanel(!NavPanel)} >
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="10" viewBox="0 0 22 10" fill="none">
                    <path d="M20.4772 5H5.81055" stroke="#EDEBE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20.4763 1H1.61914" stroke="#EDEBE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20.4763 9H1.61914" stroke="#EDEBE9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              
              </div>
            </div>
          </div>
        </div>
        <div className="lol">
          <div className={`open c1 ${PogodaPanel ? '' : 'closed'}`}>
            <Weather />
          </div>
          <div className={`open c2 ${VolutaPanel ? '' : 'closed'}`}>
            <Currency />
          </div>
        </div>
        <div className={`open nav_burger ${NavPanel ? '' : 'closed'}`} >
          <div className="left">
            <Link to={'/'} className='link'><img src={logo} alt="" /></Link>
            <Link to={"/"} className='link'>Башкы бет</Link>
            <Link to={"/News"} className='link'>Жаңылыктар</Link>
            <Link to={"/Tv-program"} className='link'>Программалар</Link>
            <Link to={"/Gallery"} className='link'>Фото баян</Link>
            <Link to={"/About"} className='link'>Биз жөнүндө</Link>
            <Link to={"/InfoGraf"} className='link link-has_arrow'>Инфографика</Link>
            <Link to={"#contacts"} className='link link-has_arrow'>Байланыш</Link>
            <Link to={"/Publication"} className='link link-has_arrow'>Жарнама</Link>
            <a className="date__btn">
              <img src={date} alt="" />
              {formatDate(currentDate)}
            </a>
            <a href="#" className='youtube'>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
                <path d="M1.09863 1.84134C1.09863 1.04818 1.97782 0.570705 2.64311 1.00256L9.81991 5.66122C10.4273 6.05548 10.4273 6.94452 9.81991 7.33878L2.64311 11.9974C1.97782 12.4293 1.09863 11.9518 1.09863 11.1587V1.84134Z" fill="#EDEBE9" stroke="#EDEBE9" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Youtube
            </a>
          </div>
          <div className="right">
            <Weather />
            <Currency />
          </div>

        </div>
      </div>
    </>
  )
}
