import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import NewsCard from '../News/components/NewsCard/NewsCard'
import down from '../../assets/icons/Down.svg'
import './Serch.scss'
import { useSelector, useDispatch } from "react-redux";
import { getNews } from '../../redux/reducers/app'
import HeaderADS from '../Header/components/HeaderADS/HeaderADS'

export default function Search() {
  const news = useSelector(s => s.app.news);
  const dispatch = useDispatch();
  const [allNews, setAllNews] = useState([])
  const [ind, setind] = useState(10)
  const [search, setsearch] = useState('')
  const [count, setCount] = useState(0)


  useEffect(() => {
      dispatch(getNews('','', ind));
  }, [ind])
  useEffect(() => {
    if (news.results) {
      if (ind>10) {
        setAllNews((prevNews) => [...prevNews, ...news.results]);
      }
      else{
        setAllNews(news.results)
        setCount(news.count)
      }
    }
  }, [news.results,search])
  useEffect(() => {
    let res = allNews
    res = res.filter(e => e.title.toLowerCase().includes(search.toLowerCase()) || e.description.toLowerCase().includes(search.toLowerCase()));
    if(res.length>0 )setAllNews(res)
  }, [search])
  return (
    <>
      <HeaderADS />

      <Header top={'18vh'} />
      <div className='news_search'>
        <div className="container ">
          <div className="search">
            <h1 className='title'>Поиск по:</h1>
            <input type="text" onChange={e=>setsearch(e.target.value)}/>
          </div>
          <div className="news_search__inner2 ">
            <div className="news_search__content">
              <div className="cards">
                {
                  allNews ? (allNews.map(e => (
                    <NewsCard news={e} />
                  ))) : (
                    <h1>Ничего не найдено</h1>
                  )

                }
                {count >= ind ? (
                  <div onClick={() => setind(ind + 10)} className="card last">
                    <p>Баарын көрүү</p>
                    <img src={down} alt="" />
                  </div>
                ) : (<></>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
