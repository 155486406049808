import React from 'react'
import view from '../../../../../assets/icons/glas.png'
import './newscard.scss'
import { Link } from "react-router-dom";

export default function NewsCard({news}) {
    const backgroundStyle = {
        backgroundImage: `url(${news.image})`,
    }
  return (
    <a href={`/OneNews/${news.id}`} className='card' style={backgroundStyle}>
        <div className="wrapper">
            <div className="card__top">
                <Link to={'/News'} key={news.categories[0].id} className='category__button'>{news.categories[0].name}</Link>
                <div className="views">
                    <img src={view} alt="" />
                    {news.views}
                </div>
            </div>
            <div className="card__bottom">
                <span>{news.created}</span>
                <p><Link to={`/OneNews/${news.id}`}>{news.title}</Link></p>
            </div>
        </div>
    </a>
  )
}
