import React, { useState, useEffect } from "react";
import "./youtube.scss";
import { useSelector, useDispatch } from "react-redux";
import { getYtVideo } from "../../redux/reducers/app";
export default function YouTube() {
  const yt_video = useSelector((s) => s.app.yt_video);
  const dispatch = useDispatch();
  const [YT, setYT] = useState([]);
  const [YTmain, setYTMain] = useState({});
  useEffect(() => {
    dispatch(getYtVideo());
  }, []);
  useEffect(() => {
    if (yt_video.results) {
      setYTMain(yt_video.results.slice(-1)[0]);
      setYT(yt_video.results.slice(-7,-1));
      console.log(YTmain.youtube);
    }
  }, [yt_video.results]);
  return (
    <div className="YouTube container">
      <div className="main__video">
        <iframe
          width="100%"
          height="100%"
          src={YTmain.youtube}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <h2>{YTmain.title}</h2>
      </div>
      <div class="scrollable-container">
        <div class="scrollable-content">
          {YT.map(e=>(
            <iframe
            width="560"
            height="315"
            src={e.youtube}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          ))}
        </div>
      </div>
      <div class="scrollable-container scrollable-container-active">
        <div class="scrollable-content">
        {YT.map(e=>(
            <iframe
            width="560"
            height="315"
            src={e.youtube}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          ))}
        </div>
      </div>
    </div>
  );
}
