import React, { useState, useEffect } from 'react'
import './sidebar.scss'
import { useSelector, useDispatch } from "react-redux";
import { getCategories } from '../../redux/reducers/app';
import { Link } from 'react-router-dom';

export default function SideBar({setCat, cat}) {
  const categories = useSelector(s => s.app.categories);
  const dispatch = useDispatch();
  const [allcategories, setCategories] = useState([])
  useEffect(() => {
    dispatch(getCategories());
  }, [])
  useEffect(() => {
    if (categories.results) {
      setCategories(categories.results)
    }
  }, [categories.results])
  return (
    <div className='sideBar'>
      <h2 className="mini__title">
        РУБРИКАЛАР
      </h2>
      {
 
        allcategories!==[]?(
        allcategories.map(e=>(
          cat?(
          <button key={e.id} onClick={()=>setCat(e)} className={e===cat?'sidebar__btn active':'sidebar__btn'}>
            {e.name}  
          </button>):(
          <Link key={e.id} to={'/News'} className={e===cat?'sidebar__btn active':'sidebar__btn'}>
            {e.name}  
          </Link>)
        ))):"Загрузка..."
      }

    </div>
  )
}
