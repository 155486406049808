import React from 'react'
import HeaderADS from '../Header/components/HeaderADS/HeaderADS'  
import MainSlider from './components/MainSLider/MainSlider'
import MainNews from './components/MainNews/MainNews'
import News from './components/News/News'
import YouTube from '../YouTube/YouTube'
import Header from '../Header/Header'
import './home.scss'
import Add from '../Add/Add'
export default function Home() {

  return (
    <div className='home'>
      <Header top={'18vh'}/>
      <HeaderADS/>
      <MainSlider/>
      <MainNews/>
      <News/>
      <Add background={''}/>
      <YouTube/>
    </div>
  )
}
