import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import NewsCard from './components/NewsCard/NewsCard'
import SideBar from '../SideBar/SideBar'
import down from '../../assets/icons/Down.svg'
import './News.scss'
import { useSelector, useDispatch } from "react-redux";
import { getNews, getPopular } from '../../redux/reducers/app'
import HeaderADS from '../Header/components/HeaderADS/HeaderADS'

export default function News() {
  const news = useSelector(s => s.app.news);
  const popular = useSelector(s => s.app.popular);
  const dispatch = useDispatch();
  const [allNews, setAllNews] = useState([])
  const [popularNews, setPopularNews] = useState([])
  const [cat, setCat] = useState({ 'test': 'test' })
  const [cat2, setCat2] = useState({ 'test': 'test' })
  const [ind, setind] = useState(10)
  const [ind2, setind2] = useState(10)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (cat !== { 'test': 'test' }) {
      dispatch(getPopular(cat.id));
    } else if(ind2!==0){
      dispatch(getPopular(cat.id, null, ind2, ind2+10));
    }else{
      dispatch(getPopular());
    }
    if(ind>10){
      console.log('ind resp');
      dispatch(getNews(null, null, ind));
    }else if (cat2 !== { 'test': 'test' }) {
      dispatch(getNews(cat2.id));
      
    }
    
  }, [cat, cat2, ind, ind2])
  useEffect(() => {
    if (news.results) {
      if(ind>10 && news.results){
        setAllNews((prevNews) => [...prevNews, ...news.results]);
      }
      else{
        setAllNews(news.results)
        setCount(news.count)
      }
    }
  }, [news.results])
  return (
    <>
      <HeaderADS />

      <Header top={'18vh'} />
      <div className='news'>
        <div className="container ">
          <h1 className='title'>Акыркы жаңылыктар</h1>
          <div className="news__inner2 ">
            <SideBar setCat={setCat} cat={cat} />
            <div className="news__content has__hr">
              <div className="cards">
                {
                  allNews ? (allNews.map(e => (
                    <NewsCard news={e} />
                  ))) : 'Загрузка...'

                }
                {count >= ind ? (
                  <div onClick={() => setind(ind + 10)} className="card last">
                    <p>Баарын көрүү</p>
                    <img src={down} alt="" />
                  </div>
                ) : (<></>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='news__hr' />
      <div className='news hasn_pad'>
        <div className="container">
          <h1 className='title'>Баардык</h1>
          <div className="news__inner2">
            <SideBar setCat={setCat2} cat={cat2} />
            <div className="news__content">
              <div className="cards">
                {
                  allNews ? (allNews.map(e => (
                    <NewsCard news={e} />
                  ))) : 'Загрузка...'

                }
                {count >= ind2 ? (
                  <div onClick={() => setind2(ind2 + 10)} className="card last">
                    <p>Баарын көрүү</p>
                    <img src={down} alt="" />
                  </div>
                ) : (<></>)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
